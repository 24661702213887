import "./info-remedies.scss";

import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

const InfoRemedy: React.FC = () => {
  return (
    <div className={"m-info-remedy"}>
      <div className={"a-remedy-image a-remedy-image--creme"}>
        {/*<img src={cremeCircle} className={"a-circle-rotate"} alt={"Círculo"} />*/}
        <StaticImage
          src={"../../../../assets/images/remedio.png"}
          alt={"Comprar ahora flores de Bach"}
          className={"a-remedy-image__img"}
        />
      </div>
      {/*TODO checklist dont see*/}
      <div style={{ padding: "1em" }}>
        <ul className={"m-list-check"}>
          <li className={"a-list-check__item"}>
            ✓ 100% Natural, sin contraindicaciones
          </li>
          <li className={"a-list-check__item"}>✓ Gastos de envío Gratis</li>
        </ul>
        <h2>Preparado Floral personalizado</h2>
        <p>
          Siguiendo la filosofía del Dr. Bach, ponemos a tu disposición las 38
          Flores de Bach para que puedas elaborar tu propia fórmula floral.
        </p>
        <Link to={"/preparado-floral/"} className={"a-button is-inline-block"}>
          Comprar tu preparado
        </Link>
      </div>
    </div>
  );
};

export default InfoRemedy;
