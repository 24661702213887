import React from "react";

import Layout from "../components/common/layout/layout";
import SEO from "../components/common/SEO/SEO";
import Guide from "../views/shop/guide/Guide";
import InfoRemedy from "../views/shop/remedies/info/InfoRemedy";
import InfoRemedyRescue from "../views/shop/remedies/info/InfoRemedyRescue";
import {Link} from "gatsby";

const HomePage = () => {


  return (
    <Layout>
      <SEO
        title="Listado y descripción de las Flores de Bach"
        description="Entiende las Flores de Bach. Te explicamos cada una de ellas y sus principales funciones para que las conozcas y entiendas los remedios que ofrecen."
        canonical={"listado-flores-bach"}
      />
      <main className={"container"}>
        <div className="grid-x2">
          <h1 className={"text-rignt a-line-right"}>Guía Flores de Bach</h1>
          <div>
            <h2>
              Encuentra tus flores de bach
            </h2>
            <p>
              Este es un buscador especial con el que puedes encontrar las Flores de Bach idóneas para ti.
              Introduce una <b>palabra clave </b> y aparecerán las flores relacionadas.
            </p>
            <p>
              Puedes buscar por el nombre, el grupo o por <b>emociones, sentimientos, adjetivos…</b>
            </p>
            <p>
              Usa tu intuición y añade a tu <b><Link to={"/preparado-floral/"}> Preparado Floral </Link></b> aquellas que más te
              resuenen.
            </p>
          </div>
        </div>
        <Guide />
      </main>
      <section className="grid-x2 grid-x2--break-pro">
        <InfoRemedy/>
        <InfoRemedyRescue/>
      </section>


    </Layout>
  );
};

export default HomePage;
