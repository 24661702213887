import "./info-remedies.scss";

import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

const InfoRemedyRescue: React.FC = () => {
  return (
    <div className={"m-info-remedy-rescue"}>
      <div>
        <div className={"a-remedy-image a-remedy-image--yellow"}>
          <StaticImage
            src={"../../../../assets/images/remedio.png"}
            alt={"Comprar ahora flores de Bach"}
            className={"a-remedy-image__img"}
          />
        </div>
        <div style={{ padding: "1em" }}>
          <ul className={"m-list-check a-list-check__item"}>
            <li className={"a-list-check__item"}>
              ✓ Fórmula de Crisis original del Dr. Bach.
            </li>
            <li className={"a-list-check__item"}>✓ Gastos de envío Gratis</li>
          </ul>
          <h2>Remedio urgencia Flores de Bach </h2>
          <p>
            Fórmula de crisis desarrollada por el Dr. Edward Bach, compuesta por
            cinco flores: Rock Rose, Star of Bethlehem, Cherry Plum, Clematis y
            Impatiens. Es ideal para momentos de crisis en los que se requiere
            de actuación urgente y rápida.
          </p>
        </div>
        <Link to={"/remedio/"} className={"a-button is-inline-block"}>
          Comprar tu remedio
        </Link>
      </div>
    </div>
  );
};

export default InfoRemedyRescue;
