import "./guide.scss";

import * as React from "react";
import { useLayoutEffect, useState } from "react";

import { Flower } from "../../../contexts/bach/domain/flower";
import { flowers } from "../../../data/flowers";

interface FlowerSearch extends Flower {
  search: string;
}

const Guide: React.FC = () => {
  const [filterFlowers, setFlowers] = useState<FlowerSearch[]>([]);

  const allFlowers: FlowerSearch[] = flowers.map((flower) => {
    const paramsLowerCase: string = ` ${flower.name.toLowerCase()} ${flower.family.toLowerCase()} ${flower.description.toLowerCase()} ${flower.emotions.toLowerCase()} ${flower.keywords.toLowerCase()}`;
    const search = paramsLowerCase
      .normalize("NFD")
      .replace(
        /([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,
        "$1"
      )
      .normalize();
    return {
      ...flower,
      search,
    };
  });
  useLayoutEffect(() => {
    setFlowers(allFlowers);
  }, []);

  const handlerInput = (event) => {
    if (!event.target.value.toString()) {
      setFlowers(allFlowers);
      return;
    }
    const input = event.target.value.toString().toLowerCase();
    const inputNormalized = input
      .normalize("NFD")
      .replace(
        /([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,
        "$1"
      )
      .normalize();
    const filter: FlowerSearch[] = allFlowers.filter((flower) => {
      // console.log("this is log", flower.search.search(event.target.value.toString()));
      if (flower.search.search(inputNormalized) > 0) {
        return true;
      } else {
        return false;
      }
    });
    setFlowers(filter);
  };
  return (
    <div className={"l-guide"}>
      <div className="m-form">
        <input
          type={"text"}
          className={"text-center"}
          placeholder={"Búscar"}
          onChange={handlerInput}
        />
      </div>
      <section className={"a-line-center"}>
        {filterFlowers.length < 1 && (
          <p className={"text-center"}>No se han encontrado coincidencias</p>
        )}
        <div className={"o-guide"}>
          {filterFlowers.map((flower: Flower) => (
            <div key={flower.id} className={"m-guide__resume "}>
              <h2 className={"h3 m-guide__title"}>{flower.name} </h2>
              {/*<p className={"m-guide__family"}>{flower.family}</p>*/}
              <p className={"a-guide__emotions"}>{flower.emotions}</p>
              <p className={"a-guide__description"}>
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                <i>"{flower.description}"</i>
              </p>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Guide;
